interface Props {
  text: string;
  onClick?: () => void;
  className?: string;
}

import React, { memo } from 'react';

const ButtonWhite = memo((props: Props) => {
  const { text, onClick = () => {}, className = '' } = props;

  return (
    <div
      className={`flex items-center cursor-pointer whitespace-nowrap px-[10px] py-2 font-medium text-base px-6 bg-white rounded-lg font-sfprotext ${className}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
});

export default ButtonWhite;
