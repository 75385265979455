import Modal from "antd/lib/modal/Modal";
import { chains, useActiveWeb3React } from "app-sdk";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import PrimaryButton from "components/common/button";
import { useSwitchNetwork } from "hooks/useSwitchNetwork";
import Image from "next/image";
import { useCallback, useEffect, useMemo } from "react";
import { useChangeModal, useSelectModal } from "store/modal";
import { useAccount } from "wagmi";

const WalletWrongNetwork = () => {
  const { isWrongNetwork, chainId: currentChainId } = useActiveWeb3React();
  const { toggleWrongNetworkModal } = useChangeModal();
  const { wrongNetworkModal } = useSelectModal();
  const { isConnected } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    toggleWrongNetworkModal(isConnected && (isWrongNetwork || false));
  }, [isConnected, isWrongNetwork, currentChainId, toggleWrongNetworkModal]);

  const supportedMainnetChains = useMemo(
    () => chains.filter((chain) => !chain.testnet),
    []
  );
  const handleSwitchNetwork = useCallback(() => {
    switchNetwork(
      Number(process.env.NEXT_PUBLIC_CHAIN_ID) ?? supportedMainnetChains[0].id
    );
  }, [supportedMainnetChains, switchNetwork]);
  return (
    <>
      {wrongNetworkModal && (
        <Modal
          open={wrongNetworkModal}
          closable={true}
          className="fashion-modal"
          footer={null}
          width={320}
          onCancel={() => toggleWrongNetworkModal(false)}
          closeIcon={
            <Image src="/icons/close-icon.svg" alt="" width={12} height={12} />
          }
        >
          <WrapCustomBorder className="z-10" />

          <div className="py-7 text-center relative z-10">
            <div className="text-lg font-semibold pt-4 pb-2 text-center">
              Wrong network
            </div>
            <div className="text-center pb-2 text-sm font-normal">
              Please change network on your wallet to Polygon
            </div>
            <div className="flex justify-center mt-3">
              <PrimaryButton text="Switch network" onClick={handleSwitchNetwork} />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WalletWrongNetwork;
