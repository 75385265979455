import { Box } from "app-sdk";
import WalletCard from "components/widgets/WalletCard";
import useAuth from "hooks/useAuth";
import { useWallet } from "hooks/useWallet";
import { useChangeModal, useSelectModal } from "store/modal";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import Modal from "antd/lib/modal/Modal";
import React from 'react';

const WalletModal: React.FC<React.PropsWithChildren> = () => {
  const { isWalletConnected } = useSelectModal();
  const { wallets } = useWallet();
  const { login } = useAuth();
  const { setWallet } = useChangeModal();

  return (
    <>
      {isWalletConnected && (
        <Modal
          title={
            <div className="font-bold text-32px flex justify-center flex-col items-center gap-2  px-4 xl:px-8 pt-3 xl:pt-6 ">
              <p className="text-gradient mt-1">Connect your wallet to Login</p>
              <p className="text-gray-300 text-center text-xl font-medium">
                If you don&apos;t have a wallet, you can select a provider and
                create one now.
              </p>
            </div>
          }
          open={isWalletConnected}
          onCancel={() => setWallet(false)}
          className="fashion-modal"
          style={{ top: 16 }}
          footer={false}
        >
          <WrapCustomBorder />
          <div className=" px-4 xl:px-8 pb-4 xl:pb-8">
            <div className="grid gap-5 ">
              {wallets.map((wallet) => (
                <Box key={wallet.title}>
                  <WalletCard wallet={wallet} login={login} />
                </Box>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default WalletModal;
