import { multicallAddresses } from 'ethereum-multicall';
import { useMemo } from 'react';

import { Contract } from '@ethersproject/contracts';

import {
  Erc20,
  Erc20__factory,
  Multicall,
  Multicall__factory,
} from '../config/abi/types';
import { getAddress, getContract } from '../utils';
import { useActiveWeb3React } from './useActiveWeb3React';
import { useProviderOrSigner } from './useProviderOrSigner';

export function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider } = useActiveWeb3React();
  const providerOrSigner = useProviderOrSigner(withSignerIfPossible) ?? provider;

  const canReturnContract = useMemo(() => address && ABI && providerOrSigner, [address, ABI, providerOrSigner]);

  return useMemo(() => {
    if (!canReturnContract) return null;
    try {
      return getContract(address, ABI, providerOrSigner);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, providerOrSigner, canReturnContract]) as T;
}

export function useTokenContract(tokenAddress: string, withSignerIfPossible?: boolean) {
  return useContract<Erc20>(tokenAddress, Erc20__factory.abi, withSignerIfPossible);
}

export function useMulticallContract() {
  const { chainId } = useActiveWeb3React();
  return useContract<Multicall>(getAddress(multicallAddresses, chainId), Multicall__factory.abi, false);
}
