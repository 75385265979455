import Link from "next/link";
import React, { memo } from "react";
import Image from "next/image";
interface IProps {
  logoBlack?: boolean;
}
const ApevineIcon = memo(({ logoBlack }: IProps) => {
  return (
    <Link href="/">
      <Image
        src={"/images/logo-header.png"}
        alt="logo"
        width={240}
        height={80}
      />
    </Link>
  );
});
ApevineIcon.displayName = "ApevineIcon";

export default ApevineIcon;
