import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define a type for the slice state
interface ModalState {
  isWalletConnected: boolean;
  confirmStepModal: boolean;
  purchaseSuccessfullyModal: boolean;
  buyNowAcceptOfferModal: boolean;
  confirmModal: boolean;
  makeOfferModal: boolean;
  createCollectionModal: boolean;
  wrongNetworkModal: boolean;
  checkBrowserModal: boolean;
}

// Define the initial state using that type
const initialState: ModalState = {
  isWalletConnected: false,
  confirmStepModal: false,
  purchaseSuccessfullyModal: false,
  buyNowAcceptOfferModal: false,
  confirmModal: false,
  makeOfferModal: false,
  createCollectionModal: false,
  wrongNetworkModal: false,
  checkBrowserModal: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalWallet: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnected = action.payload;
    },
    setModalConfirmStep: (state, action: PayloadAction<boolean>) => {
      state.confirmStepModal = action.payload;
    },
    setModalPurchaseSuccessfully: (state, action: PayloadAction<boolean>) => {
      state.purchaseSuccessfullyModal = action.payload;
    },
    setModalBuyNowAcceptOffer: (state, action: PayloadAction<boolean>) => {
      state.buyNowAcceptOfferModal = action.payload;
    },
    setModalConfirm: (state, action: PayloadAction<boolean>) => {
      state.confirmModal = action.payload;
    },
    setModalMakeOffer: (state, action: PayloadAction<boolean>) => {
      state.makeOfferModal = action.payload;
    },
    setModalCreateCollection: (state, action: PayloadAction<boolean>) => {
      state.createCollectionModal = action.payload;
    },
    setModalWrongNetwork: (state, action: PayloadAction<boolean>) => {
      state.wrongNetworkModal = action.payload;
    },
    setModalCheckBrowser: (state, action: PayloadAction<boolean>) => {
      state.checkBrowserModal = action.payload;
    },
  },
});

export const {
  setModalWallet,
  setModalConfirmStep,
  setModalPurchaseSuccessfully,
  setModalBuyNowAcceptOffer,
  setModalConfirm,
  setModalMakeOffer,
  setModalCreateCollection,
  setModalWrongNetwork,
  setModalCheckBrowser,
} = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const useSelectModal = () => useSelector((state: RootState) => state.modal);

export const useChangeModal = () => {
  const dispatch = useAppDispatch();

  const setWallet = (visible: boolean) => {
    dispatch(setModalWallet(visible));
  };

  const toggleConfirmStepModal = (visible: boolean) => {
    dispatch(setModalConfirmStep(visible));
  };

  const togglePurchaseSuccessfullyModal = (visible: boolean) => {
    dispatch(setModalPurchaseSuccessfully(visible));
  };

  const toggleBuyNowAcceptOfferModal = (visible: boolean) => {
    dispatch(setModalBuyNowAcceptOffer(visible));
  };

  const toggleConfirmModal = (visible: boolean) => {
    dispatch(setModalConfirm(visible));
  };

  const toggleMakeOfferModal = (visible: boolean) => {
    dispatch(setModalMakeOffer(visible));
  };

  const toggleCreateCollectionModal = (visible: boolean) => {
    dispatch(setModalCreateCollection(visible));
  };

  const toggleWrongNetworkModal = (visible: boolean) => {
    dispatch(setModalWrongNetwork(visible));
  };

  const toggleCheckBrowserModal = (visible: boolean) => {
    dispatch(setModalCheckBrowser(visible));
  };

  return {
    setWallet,
    toggleConfirmStepModal,
    togglePurchaseSuccessfullyModal,
    toggleBuyNowAcceptOfferModal,
    toggleConfirmModal,
    toggleMakeOfferModal,
    toggleCreateCollectionModal,
    toggleWrongNetworkModal,
    toggleCheckBrowserModal
  };
};

export default modalSlice.reducer;
