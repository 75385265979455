import { apiUrl } from "config";
import { isNil } from "lodash";
import store from "store";
import { userSlide } from "store/user";
import { IRefreshToken, ITokens } from "types/auth";

let refreshTokenRequest: Promise<ITokens> | null = null;

const isExpired = (date: string) => {
  // return new Date(date).getTime() < Date.now() + 31 * 24 * 60 * 60_000 - 5_000;
  return new Date(date).getTime() < Date.now() + 15 * 60_000;
};

const refreshToken = async (refreshToken: string) => {
  const tokensData = await fetch(apiUrl + "/auth/refresh-tokens", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refreshToken }),
  });
  if (!tokensData.ok) throw tokensData;
  const tokensJson = (await tokensData.json()) as IRefreshToken;
  return tokensJson.tokens;
};

const setTokens = (tokens: ITokens | null) =>
  store?.dispatch(userSlide.actions.setTokens(tokens));

async function getAccessToken() {
  try {
    let tokens = store?.getState().user.tokens;
    if (!tokens) return null;

    try {
      if (isExpired(tokens.access.expires)) {
        if (isExpired(tokens.refresh.expires))
          throw new Error("refresh token expired");

        refreshTokenRequest = refreshTokenRequest
          ? refreshTokenRequest
          : refreshToken(tokens.refresh.token);
        const newToken = await refreshTokenRequest;
        // reset token request for the next expiration
        refreshTokenRequest = null;

        await setTokens(newToken);
        return newToken.access.token;
      }
    } catch (err) {
      console.error(err);
      // reset tokens
      await setTokens(null);
      return null;
    }
    return tokens?.access.token;
  } catch {
    return null;
  }
}

async function updateOptions(options?: RequestInit) {
  const update = { ...options };
  const accessToken = await getAccessToken();
  if (accessToken) {
    update.headers = {
      ...update.headers,
      // "x-header-access-code": sessionStorage.getItem("code") ?? "",
      "x-header-access-code": process.env.NEXT_PUBLIC_ACCESS_CODE || '',
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    update.headers = {
      ...update.headers,
      // "x-header-access-code": sessionStorage.getItem("code") ?? "",
      "x-header-access-code": process.env.NEXT_PUBLIC_ACCESS_CODE || '',
    };
  }
  return update;
}
export const fetcher = async <Respone = any>(
  input: RequestInfo | URL,
  options?: RequestInit,
  version?: number
) => {
  const domain = apiUrl;
  return fetch(domain + input, await updateOptions(options)).then(
    async (res) => {
      const data = await res.json();
      if (!res.ok) {
        console.error(data.message);
        if (data.message === "INVALID_ACCESS_CODE") {
          sessionStorage.removeItem("accessCode");
          window.dispatchEvent(new Event("storage"));
        }
        throw data;
      }
      return data as Respone;
    }
  );
};

export const fetcherPost = async (
  input: RequestInfo | URL,
  body: object,
  options?: RequestInit,
  version?: number
) => {
  return fetcher(
    input,
    await updateOptions({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      ...options,
    }),
    version
  );
};

export const uploadPost = async (
  input: RequestInfo | URL,
  body: any,
  options?: RequestInit,
  version?: number
) => {
  return fetcher(
    input,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
      ...options,
    },
    version
  );
};

export const urlQuery = (paramsInput: any, prefix = "?") => {
  if (!paramsInput) return "";
  const params = Object.assign(paramsInput, {});

  for (const key of Object.keys(params)) {
    if (
      isNil(params[key]) ||
      (Array.isArray(params[key]) && params[key].length === 0)
    ) {
      delete params[key];
    }
  }
  return prefix + new URLSearchParams(params).toString();
};

export const simpleFetcher = async <Respone = any>(
  input: RequestInfo | URL,
  options?: RequestInit,
  version?: number
) => {
  const domain = apiUrl;
  return fetch(domain + input).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      console.error(data.message);
      if (data.message === "INVALID_ACCESS_CODE") {
        sessionStorage.removeItem("accessCode");
        window.dispatchEvent(new Event("storage"));
      }
      throw data;
    }
    return data as Respone;
  });
};

export const handleSubScribeEmail = async (email: string) => {
  console.log(
    'sessionStorage.getItem("accessCode")',
    sessionStorage.getItem("code")
  );
  return await fetch(apiUrl + "/subscribers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "x-header-access-code": sessionStorage.getItem("code") ?? "",
      "x-header-access-code": process.env.NEXT_PUBLIC_ACCESS_CODE || '',
    },
    body: JSON.stringify({
      email,
    }),
  });
};

export const fetcherPatch = async (
  input: RequestInfo | URL,
  body: object,
  options?: RequestInit,
  version?: number
) => {
  return fetcher(
    input,
    await updateOptions({
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      ...options,
    }),
    version
  );
};

export const fetcherDelete = async (
  input: RequestInfo | URL,
  options?: RequestInit,
  version?: number
) => {
  return fetcher(
    input,
    await updateOptions({
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      ...options,
    }),
    version
  );
};
