import Button, { ButtonProps } from "antd/lib/button";
import styled from "styled-components";
export const StyledButtonPrimary = styled(({ ...antdProps }) => (
  <Button {...antdProps} />
))<ButtonProps>`
  color: black !important;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.88) 0%,
      rgba(0, 0, 0, 0.88) 100%
    ),
    linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%);
  border-radius: ${(props) =>
    `${props?.radius ?? "64px"} 64px  64px ${
      props?.radius ?? "64px"
    }`} !important;
  z-index: 1;
  border: 0;
  background: linear-gradient(
    180deg,
    hsla(36, 45%, 47%, 1) 0%,
    hsla(37, 42%, 22%, 1) 26%,
    hsla(37, 46%, 15%, 1) 51%,
    hsla(36, 46%, 29%, 1) 78%,
    hsla(36, 45%, 47%, 1) 100%
  );
  &.ant-btn-default::after {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1.5px;
    right: 1.5px;
    content: "";
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.88) 0%,
        rgba(0, 0, 0, 0.88) 100%
      ),
      linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%);
    border-radius: ${(props) =>
      `${props?.radius ?? "64px"} 64px  64px ${
        props?.radius ?? "64px"
      }`} !important;
    z-index: -1;
  }

  &.ant-btn-default:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #8fc4eb;
    opacity: 0.4;
    border-color: #8fc4eb;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
  }
  &.ant-btn-default:not(:disabled):hover {
    box-shadow: 0px 0px 8px 0px rgba(217, 189, 92, 0.64) inset;
    color: black !important;
    background: linear-gradient(
      180deg,
      hsla(36, 45%, 47%, 1) 0%,
      hsla(37, 42%, 22%, 1) 26%,
      hsla(37, 46%, 15%, 1) 51%,
      hsla(36, 46%, 29%, 1) 78%,
      hsla(36, 45%, 47%, 1) 100%
    ) !important;
    &.ant-btn-default::after {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.88) 0%,
          rgba(0, 0, 0, 0.88) 100%
        ),
        linear-gradient(180deg, #794c03 0%, #ffeac7 48.96%, #7e5d28 100%) !important;
    }
  }
  &.ant-btn-default:disabled {
    opacity: 0.4;
    color: #31323c !important;
    background: #31323c;
    border-color: green;
  }
  &.ant-btn-default:not(:disabled):active {
    border: 0;
    border-color: none !important;
    background: linear-gradient(
      180deg,
      hsla(36, 45%, 47%, 1) 0%,
      hsla(37, 42%, 22%, 1) 26%,
      hsla(37, 46%, 15%, 1) 51%,
      hsla(36, 46%, 29%, 1) 78%,
      hsla(36, 45%, 47%, 1) 100%
    ) !important;
  }
  &.ant-btn-default:focus {
    border: 0;
    background: linear-gradient(
      180deg,
      hsla(36, 45%, 47%, 1) 0%,
      hsla(37, 42%, 22%, 1) 26%,
      hsla(37, 46%, 15%, 1) 51%,
      hsla(36, 46%, 29%, 1) 78%,
      hsla(36, 45%, 47%, 1) 100%
    );
  }
  &.ant-btn > .ant-btn-loading-icon {
    color: rgb(244 238 157);
  }
  &.ant-btn.ant-btn-loading::before {
    background: black;
    opacity: 0.3;
  }
`;
