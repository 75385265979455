import { Link, WalletConfig } from "app-sdk";
import Image from "next/image";
import { useCallback, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { useChangeModal } from "store/modal";
import styled from "styled-components";

interface Props<T> {
  wallet: WalletConfig<T>;
  login: any;
}

const WalletButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  padding: 18px 32px;
  @media (max-width: 768px) {
    padding: 12px 24px;ant-modal-body
  }
`;

const WalletCard = ({ wallet, login }: Props<any>) => {
  const { title, icon: Icon, installed, downloadLink, connectorId } = wallet;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setWallet, toggleCheckBrowserModal } = useChangeModal();
  const connectAction = useCallback(async () => {
    if ((installed === false && isMobile) || connectorId === "walletConnect") {
      setWallet(false);
    }
    if (installed === false) {
      toggleCheckBrowserModal(true);
      return;
    }
    setIsLoading(true);
    await login(wallet.connectorId);
    setIsLoading(false);
    setWallet(false);
  }, [
    connectorId,
    installed,
    login,
    setWallet,
    toggleCheckBrowserModal,
    wallet.connectorId,
  ]);
  let linkAction: any = {
    onClick: connectAction,
  };

  if (installed === false && isDesktop && downloadLink?.desktop) {
    linkAction = {
      as: Link,
      href: downloadLink.desktop,
      style: {
        textDecoration: "none",
      },
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }
  if (
    typeof window !== "undefined" &&
    !window.ethereum &&
    wallet.href &&
    isMobile
  ) {
    linkAction = {
      style: {
        textDecoration: "none",
      },
      as: Link,
      href: wallet.href,
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }

  return (
    <WalletButton {...linkAction} className="custom-border rounded-[32px]">
      <div className="text-gradient text-24px flex gap-3 items-center">
        <Icon width="24px" />
        {title}
      </div>
      {isLoading && (
        <Image
          src="/icons/loading.svg"
          alt=""
          width={20}
          height={20}
          className="loading-icon"
        />
      )}
    </WalletButton>
  );
};

export default WalletCard;
