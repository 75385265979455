import { useUserSelector } from "store/user";

export const useUserData = () => {
  const userData = useUserSelector().userInfo;
  return userData;
};

export const useAccessToken = () => {
  const tokenData = useUserSelector().tokens;
  return tokenData;
};
