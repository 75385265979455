import notification from "antd/lib/notification";
import {ConnectorNames, replaceBrowserHistory, useActiveChainId, useSessionChainId,} from "app-sdk";
import {magic} from "app-sdk/utils/magic";
import {useCallback} from "react";
import {connectorLocalStorageKey} from "config";
import {useUserAction} from "store/user";
import {ConnectorNotFoundError, useConnect, useDisconnect, UserRejectedRequestError,} from "wagmi";
import {WalletConnectDeeplinkChoice} from '../utils/walletconnect';

const useAuth = () => {
  const { connectAsync, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { chainId } = useActiveChainId();
  const [, setSessionChainId] = useSessionChainId();
  const { resetUser } = useUserAction();

  const login =
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID);
      try {
        await disconnectAsync()
        console.log({ findConnector, connectorID, chainId })
        const connected = await connectAsync({
          connector: findConnector,
          chainId,
        });
        const walletConnectDeeplink = window?.localStorage?.getItem('WALLETCONNECT_DEEPLINK_CHOICE')
        if (walletConnectDeeplink) {
          console.log('walletConnectDeeplink', walletConnectDeeplink)
          const walletConnectDeeplinkObject: {href: string, name: string} = JSON.parse(walletConnectDeeplink)
          WalletConnectDeeplinkChoice.instance.setWalletConnectDeeplinkChoice(walletConnectDeeplinkObject.name)
          window?.localStorage?.removeItem('WALLETCONNECT_DEEPLINK_CHOICE')
        }
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory("chainId", connected.chain.id);
          setSessionChainId(connected.chain.id);
        }
        notification.success({ message: "Connected to wallet successfully!" });
      } catch (error) {
        WalletConnectDeeplinkChoice.instance.setWalletConnectDeeplinkChoice('')
        window?.localStorage?.removeItem(connectorLocalStorageKey);
        notification.error({
          message: "Connecting to a wallet failed. Please try again",
        });
        if (error instanceof ConnectorNotFoundError) {
          return;
        }
        if (error instanceof UserRejectedRequestError) {
          return;
        }
        if (error instanceof Error) {
          console.error(
            error.message,
            "Please authorize to access your account"
          );
        }
      }
    }
  const logout = useCallback(async () => {
    try {
      resetUser();
      await disconnectAsync();
      localStorage.removeItem('persist:primary')
      await magic?.user.logout();
    } catch (error) {
      console.error(error);
    }
  }, [disconnectAsync, resetUser]);

  return { login, logout };
};

export default useAuth;
