export class WalletConnectDeeplinkChoice {
  private walletConnectDeeplinkChoice: string = ''

  static instance: WalletConnectDeeplinkChoice = new WalletConnectDeeplinkChoice()

  setWalletConnectDeeplinkChoice = (wallet: string) => {
    this.walletConnectDeeplinkChoice = wallet
  }

  get getWalletConnectDeeplinkChoice() {
    return this.walletConnectDeeplinkChoice
  }
}
