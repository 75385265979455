import { Box } from "app-sdk";
import { globalData } from "hooks/useGlobalData";
import Image from "next/image";
import Link from "next/link";
import { getFooterHomepage } from "services";
import useSWR from "swr";
import { formatFooterResponse, formatSocialResponse } from "utils/format";
import { Subscribe } from "views/HomePage/components/Subscribe";
import { Communities } from "views/HomePage/configs";
import { FooterConfig, IFooterTab, SocialItem } from "views/NFTMarket/types";
import { useAtom } from "jotai";
import { isValidUrl } from "validates";
const Footer = () => {
  const [, setGlobalData] = useAtom(globalData);

  const { data } = useSWR(["get-footer-conf"], async () => {
    const res: {
      footer: IFooterTab[];
      socials: SocialItem[];
      footer_config: FooterConfig;
    } = await getFooterHomepage();
    if (res) {
      setGlobalData(res.socials);
    }
    return res;
  });
  const footerResponseFormatted = formatFooterResponse(data?.footer);
  const socialResponseFormatted = formatSocialResponse(data?.socials);
  const footer_config = data?.footer_config;
  const defaultConfig = [
    {
      title: "FAQ",
      link: "faq",
      isSection: true,
    },
    {
      title: "Marketplace",
      link: "collection",
      isSection: true,
    },
    {
      title: "EARTHwise Global",
      link: "https://www.earthwise.global/",
      isExternal: true,
    },
    {
      title: "Terms of Service",
      link: "/",
    },
    {
      title: "EARTHwise Token",
      link: "/token",
      isSection: true,
    },
    {
      title: "People Involved",
      link: "/https://www.earthwise.global/game-campaign",
      isExternal: true,
    },
    {
      title: "Privacy Policy",
      link: "/",
    },
    {
      title: "Stories",
      link: "/stories",
      isSection: true,
    },
    {
      title: "Game Community",
      link: "https://discord.com/invite/CM66Dhsz8F",
      isExternal: true,
    },
    {
      title: "Whitepaper",
      link: "/",
    },
    {
      title: "Disclaimer",
      link: "https://www.earthwise.global/elowyn-game-disclaimer",
      isExternal: true,
    },
    {
      title: "Support",
      link: "https://www.earthwise.global/game-support",
      isExternal: true,
    },
  ];
  const features = footerResponseFormatted?.length
    ? footerResponseFormatted
    : defaultConfig;
  const socials = socialResponseFormatted.length
    ? socialResponseFormatted
    : Communities;
  return (
    <div className=" text-white relative text-16px">
      <Subscribe />
      <div className="relative">
        <div className="absolute bottom-[-10px] z-0">
          <Image
            src="/images/bg-footer.png"
            width={1912}
            height={1121}
            alt="banner-footer"
          />
        </div>
      </div>
      <div
        style={{
          background: "url(/images/footer-bg.png)",
        }}
        className="z-10 relative"
      >
        <section className="container mx-auto my-auto min-h-[300px] flex items-center flex-col justify-between  text-gray-300  px-4 3xl:px-0">
          <div className="flex flex-col xl:flex-row w-full justify-between gap-[2em] mt-10">
            <div className="w-fit">
              <div className="flex flex-start">
                <Image
                  src={footer_config?.image ?? "/images/logo-footer.png"}
                  alt="logo-footer"
                  width={164}
                  height={92}
                />
              </div>
              <div className="mt-2 leading-[28px]">
                {footer_config?.address ??
                  "A101, Les Terrasses du Barachois, Tamarin, 90901, Mauritius"}
              </div>
              <div className="mt-2  leading-[28px]">
                {footer_config?.support_email ??
                  "Email: support@earthwise.games"}
              </div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3  gap-[16px] w-full xl:w-1/2">
              {features.map((e) => {
                if (e.isExternal) {
                  return (
                    <a
                      href={!!e?.link ? e.link : "/"}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={e.title}
                    >
                      {" "}
                      <p className="flex justify-start leading-[28px] items-center">
                        {e.title}
                      </p>
                    </a>
                  );
                }
                return (
                  <>
                    {e.title ? (
                      <div className="flex items-center">
                        <Link
                          key={e.title}
                          href={!!e.link ? e.link : "/"}
                          className="flex justify-center leading-[28px] items-center "
                        >
                          {e.title}
                        </Link>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </>
                );
              })}
            </div>
            <div className="flex flex-col gap-[12px]  justify-center lg:justify-start items-start 2xl:items-center text-[24px]">
              Stay in touch with us
              <div className="flex gap-[8px]">
                {socials.map((e, index) => {
                  return (
                    <Box key={`footer-social-icon-${e.key}`}>
                      {e.link ? (
                        <a
                          href={
                            isValidUrl(e.link)
                              ? e.link
                              : Communities[index].link
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={e.url ?? Communities[index].url}
                            width={40}
                            height={40}
                            alt={e.key}
                          />
                        </a>
                      ) : (
                        <Image
                          src={e.url ?? Communities[index].url}
                          width={40}
                          height={40}
                          alt={e.key}
                        />
                      )}
                    </Box>
                  );
                })}
              </div>
            </div>
          </div>
          <h2 className="text-gray-400 text-[14px] text-start mb-6 w-full leading-[20px]">
            {footer_config?.collaboration ??
              "The EARTHwise Game © 2024, EARTHwise Centre. Developed by VMO in collaboration with EARTHwise."}
          </h2>
        </section>
      </div>
    </div>
  );
};

export default Footer;
