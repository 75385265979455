import { notification } from "antd";
import { RcFile } from "antd/lib/upload";

export function isNull(array?: any[]) {
  return !array?.length;
}
export const validateBeforeUpload = (
  file: RcFile,
  listType: Array<string>,
  size: number,
  message: string
) => {
  const isValidType = listType.includes(file.type);
  if (!isValidType) {
    notification.error({
      message,
    });
    return false;
  }
  const isValidSize = file.size / 1024 / 1024 <= size;
  if (!isValidSize) {
    notification.error({
      message: `Please upload file below ${size}MB.`,
    });
    return false;
  }
  return isValidType && isValidSize;
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};