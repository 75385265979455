import Modal from 'antd/lib/modal/Modal';
import { SignBtn } from 'components/SignBtn';
import Image from 'next/image';
import { useState } from 'react';

export default function WalletSignMessage() {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <Modal
      open={isShow}
      closable={true}
      className="custom-modal"
      footer={null}
      width={312}
      onCancel={() => setIsShow(false)}
      closeIcon={<Image src="/icons/close-icon.svg" alt="" width={12} height={12} />}
    >
      <div className="pt-10 pb-6 px-7">
        <div className="text-lg pb-6 flex items-center justify-center">
          <span className="pr-[10px]">Welcome to</span> <Image src="/Logo.png" alt="" width={100} height={35} />
        </div>
        <div className="text-center text-sm font-normal">
          Sign a message on your wallet to access and discover one-of-a-kind and trendy NFTs
        </div>
        <div className="flex justify-center pt-5">{isShow && <SignBtn />}</div>
      </div>
    </Modal>
  );
}
