import { Box, useActiveWeb3React } from "app-sdk";
import classNames from "classnames";
import ApevineIcon from "components/Icon/ApevineIcon";
import { AccountInfo } from "components/ModalAccountInfo/AccountInfo";
import PrimaryButton from "components/common/button";
import SecondaryButton from "components/common/secondaryButton";
import { useCheckConnected } from "hooks/useCheckConnected";
import { useWallet } from "hooks/useWallet";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { IS_HIDE_MARKETPLACE, NEXT_PUBLIC_ENV } from "../config";
import { useUserSelector } from "../store/user";
import MenuMobile from "./MenuMobile";

export const LINKS = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Marketplace",
    href: `/collection`,
  },
  {
    title: "EARTHwise Token",
    href: "/token",
  },
  {
    title: "Stories",
    href: "/stories",
  },
  {
    title: "FAQ",
    href: "/faq",
  },
];

interface IProps {
  isBgWhite?: boolean;
}

const Header = ({ isBgWhite = true }: IProps) => {
  const { account } = useActiveWeb3React();
  const { connect } = useWallet();
  const { asPath } = useRouter();
  const user = useUserSelector();
  const handleBackToHome = useCallback(() => {
    window.location.href = "/";
  }, []);
  const { loading } = useCheckConnected();
  const handleLinkToSupport = useCallback(() => {
    window.open(
      "https://www.earthwise.global/game-support",
      "_blank",
      "noopener"
    );
  }, []);

  return (
    <div className="container flex items-center w-full sm:py-7 py-4 px-3 xl:px-10 3xl:px-0 justify-between relative z-10 h-[100px] !font-elMesseri">
      <div className="absolute bottom-[-4px]">
        <Image
          src="/icons/header-line.svg"
          width={1700}
          height={8}
          alt="header-line"
        />
      </div>
      <div className="flex items-center justify-between lg:justify-start w-full">
        <Box onClick={handleBackToHome} className="cursor-pointer">
          <ApevineIcon logoBlack={isBgWhite} />
        </Box>
        <div className="flex items-center gap-3 sm:gap-12 md:gap-16 xl:gap-20 2xl:gap-40 ">
          <div className="flex items-center gap-4 sm:gap-8 lg:gap-6 xl:gap-10 hidden lg:flex mr-0 lg:mr-4 xl:mr-0">
            {LINKS.map((item, index) => {
              const isActive = asPath === item.href;
              if (IS_HIDE_MARKETPLACE && item.href === "/collection") {
                return;
              }
              return (
                <p
                  key={`header-item=${item.href}`}
                  className={classNames(
                    "hover-scale relative whitespace-nowrap",
                    {
                      "text-white": !isActive && !isBgWhite,
                      "text-gray-400": !isActive,
                      "text-gradient": isActive,
                      "text-18px": isActive,
                      " text-[16px]": !isActive,
                    }
                  )}
                >
                  {isActive && (
                    <div className="absolute bottom-[-39px] flex justify-center w-full">
                      <Image
                        src="/icons/active-menu.svg"
                        width={41}
                        height={13}
                        alt="active-menu"
                      />
                    </div>
                  )}
                  <Link href={item.href}>{item.title}</Link>
                </p>
              );
            })}
          </div>
          <MenuMobile />
        </div>
      </div>
      <div className="lg:flex hidden items-center gap-3">
        <PrimaryButton
          text="Support"
          buttonStyles=" px-8 2xl:px-12"
          onClick={handleLinkToSupport}
        />
        {account && user.tokens ? (
          <AccountInfo />
        ) : (
          <SecondaryButton
            text="Login"
            onClick={connect}
            buttonStyles=" px-8 2xl:px-12"
            loading={loading}
            disabled={NEXT_PUBLIC_ENV === "PRODUCTION"}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
