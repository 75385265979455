import React, { useCallback, useState } from "react";
import { SubscribedModal } from "./items/SubscribedModal";
import SecondaryButton from "components/common/secondaryButton";
import { useAtom } from "jotai";
import { globalData } from "hooks/useGlobalData";

export const Subscribe = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data] = useAtom(globalData);
  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);
  const handleSubScriber = useCallback(() => {
    window.open(
      data?.subscribe?.link ?? "https://www.earthwise.global/game-subscribe",
      "_blank",
      "noopener"
    );
  }, [data?.subscribe?.link]);
  return (
    <section className="relative mt-10 pl-2 pr-4 2xl:p-0 mb-20 z-20">
      {isOpenModal && (
        <SubscribedModal open={isOpenModal} onCancel={onCloseModal} />
      )}
      <div
        className="container h-full py-6  lg:py-10 h-[280px] lg:h-[300px] flex-col  flex  justify-center gap-5 items-start px-8  2xl:px-20"
        style={{
          backgroundImage: "url(/images/subscribe-bg-blur.png)",
        }}
      >
        <div className=" text-center lg:text-start leading-[36px] text-gradient text-40px font-bold z-10 text-start mt-1 2xl:mt-0">
          <p>
            {data?.subscribe?.title ??
              "Stay in touch with our latest updates and News"}
          </p>
        </div>

        <div className="flex justify-center lg:justify-start w-full">
          <SecondaryButton
            text="Subscribe"
            className="lg:w-40  h-[48px]"
            onClick={handleSubScriber}
          />
        </div>
      </div>
    </section>
  );
};
