import React, { useCallback } from "react";
import Image from "next/image";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import Modal from "antd/lib/modal";
export const SubscribedModal = ({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) => {
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);
  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      className="fashion-modal"
      footer={false}
      style={{ top: 16 }}
    >
      <WrapCustomBorder />
      <div className="flex justify-center items-center flex-col text-center gap-2 pb-10">
        <Image
          src="/images/earn-ewa.png"
          width={330}
          height={330}
          alt="earn-ewa-token"
        />
        <p className="text-gradient text-36px font-bold">Subscribed</p>
        <p className="text-20px text-gradient text-medium">
          Thank you for joining the Elowyn Game Community!
        </p>
      </div>
    </Modal>
  );
};
