export const Communities = [
  {
    key: "youtube",
    url: "/icons/youtube.svg",
    link: " https://youtube.com/playlist?list=PLCw2ubdI_u67KpBM8z8CPxKxJUzIbuup0&si=nMjHNRrIGreZRCmD",
  },
  {
    key: "discord",
    url: "/icons/discord-icon.svg",
    link: "https://discord.gg/CM66Dhsz8F",
  },
  {
    key: "telegram",
    url: "/icons/telegram.svg",
    link: "https://t.me/+XG0EGOxtAHhhMDU0",
  },
  {
    key: "instagram",
    url: "/icons/instagram-icon.svg",
    link: "https://www.instagram.com/earthwise.games/",
  },
  {
    key: "tiktok",
    url: "/icons/tiktok.svg",
    link: "",
  },
];

export const roadmap = [
  {
    key: "phase1",
    title: "Q3 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ",
  },
  {
    key: "phase1",
    title: "Q3 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ",
  },
  {
    key: "phase1",
    title: "Q3 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ",
  },
  {
    key: "phase1",
    title: "Q3 2023",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been ",
  },
];
