import { Box, useActiveWeb3React } from "app-sdk";
import ProfileModal from "layouts/ProfileModal";
import React, { useCallback, useState } from "react";
import Image from "next/image";
export const AccountInfo = () => {
  const { account } = useActiveWeb3React();
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const onClose = useCallback(() => {
    setShowProfileModal(false);
  }, []);
  const onOpen = useCallback(() => {
    setShowProfileModal(true);
  }, []);
  return (
    <div>
      {showProfileModal && (
        <ProfileModal isOpen={showProfileModal} onCancel={onClose} />
      )}
      {account && (
        <Box
          className="rounded-border rounded-[50%] w-[48px] h-[48px] flex justify-center items-center cursor-pointer"
          onClick={onOpen}
        >
          <Image
            src="/icons/profile.svg"
            width={20}
            height={20}
            alt="profile"
          />
        </Box>
      )}
    </div>
  );
};
