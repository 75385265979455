import { EthNetworkConfiguration, Magic } from 'magic-sdk';

const formattedNetwork = (): EthNetworkConfiguration => {
  return {
    rpcUrl: process.env.NEXT_PUBLIC_DEFAULT_RPC ?? 'https://polygon-mumbai.blockpi.network/v1/rpc/public',
    chainId: Number(process.env.NEXT_PUBLIC_NETWORK_ID) || 80001,
  };
};

export const magic =
  typeof window !== 'undefined'
    ? new Magic(process.env.NEXT_PUBLIC_MAGIC_API_KEY as string, {
        network: formattedNetwork(),
      })
    : null;
