import memoize from 'lodash/memoize';
import { Chain, configureChains, createClient } from 'wagmi';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';

import {
  polygon,
  polygonMumbai,
} from '../constants';
import { MagicConnectConnector } from '../connectors/magicLink';

const CHAINS: Chain[] = [
  polygon,
  polygonMumbai,
];

export const { provider, chains } = configureChains(CHAINS, [publicProvider()]);

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    // shimChainChangedDisconnect: true,
  },
});

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'app',
    appLogoUrl: './logo.png',
  },
});

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    projectId: 'afb5a2e3db81041df2eede91eed4c32a',
  },
});

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    // shimChainChangedDisconnect: true,
  },
});

export const connectorConnect = new MagicConnectConnector({
  options: {
    apiKey: process.env.NEXT_PUBLIC_MAGIC_API_KEY as string,
    magicSdkConfiguration: {
      network: {
        rpcUrl: process.env.NEXT_PUBLIC_DEFAULT_RPC ?? 'https://rpc-mumbai.maticvigil.com/', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
        chainId: Number(process.env.NEXT_PUBLIC_NETWORK_ID) || polygonMumbai.id,
      },
    },
    //...Other options (check out full API below)
    networks: chains.map((c) => c.id) as any,
  },
});

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [walletConnectConnector, metaMaskConnector,connectorConnect],
});

export const CHAIN_IDS = chains.map((c) => c.id);

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId));
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet);
