import { Chain } from 'wagmi';
import {
  avalanche,
  avalancheFuji,
  bsc,
  bscTestnet,
  fantom,
  fantomTestnet,
  goerli,
  mainnet,
  polygon,
} from 'wagmi/chains';

export { mainnet, avalanche, avalancheFuji, bsc, bscTestnet, fantom, fantomTestnet, goerli, polygon };

export const polygonMumbai: Chain = {
    ...bscTestnet,
  rpcUrls: {
    default: {
      http: [
        process.env.NEXT_PUBLIC_RPC_MUMBAI ||
          'https://data-seed-prebsc-1-s1.binance.org:8545',
      ],
    },
    public: {
      http: [
        process.env.NEXT_PUBLIC_RPC_MUMBAI ||
          'https://data-seed-prebsc-1-s1.binance.org:8545',
      ],
    },
  },
};
