import { useAccount } from 'wagmi';
import { useActiveWeb3React } from 'app-sdk';
import { useState, useEffect } from 'react';
import { magic } from 'app-sdk/utils/magic';

export const useCheckConnected = () => {
  const { account } = useActiveWeb3React();
  const [loading, setLoading] = useState<boolean>(false);
  const { address } = useAccount();

  useEffect(() => {
    if (address && account) return;

    (async () => {
      setLoading(true);
      const isLoggedIn = await magic?.user.isLoggedIn();
      if (isLoggedIn === true || isLoggedIn === false) {
          setLoading(false);
      }
    })();
  }, [address, account]);

  return {
    loading,
  };
};

