import { useCallback, useMemo, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";

const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const videoRef: any = useRef(null);

  const handleVideoEnded = useCallback(() => {
    const startTime = 0;

    if (videoRef.current) {
      videoRef.current.currentTime = startTime;
      videoRef.current.play();
    }
  }, []);
  const checkIOSDevice = useCallback(() => {
    return (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }, []);
  const renderVideo = useMemo(() => {
    const isIOS = checkIOSDevice();
    return (
      <video
        ref={videoRef}
        muted
        autoPlay
        preload="auto"
        className="video"
        height={"100%"}
        width={"100%"}
        onEnded={handleVideoEnded}
      >
        <source
          src={isIOS ? "/videos/hero-banner.mp4" : "/videos/hero-banner.webm"}
          type={isIOS ? "video/mp4" : "video/webm"}
        />
        Your browser does not support the video tag.
      </video>
    );
  }, [checkIOSDevice, handleVideoEnded]);
  return (
    <div className="bg-[#01010C] relative" style={{ scrollBehavior: "smooth" }}>
      <div className="absolute w-full h-full bg-[#01010C] mt-[90px] lg:mt-0">
        {renderVideo}
      </div>
      {<Header />}

      <main className={"flex-1"}>{children}</main>

      <Footer />
    </div>
  );
};

export default MainLayout;
