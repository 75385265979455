import {
  connectorConnect,
  MagicLink,
  MetamaskIcon,
  useActiveWeb3React,
  WalletConnectIcon,
} from "app-sdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from 'styled-components';

const StyledMagicLink = styled.div`
  &:hover {
    p {
      text-decoration: underline;
    }
  }
`

export const ConnectorName = () => {
  const { account } = useActiveWeb3React();
  const [walletName, setWalletName] = useState("");
  const handleMagicShowNFT = useCallback(() => {
    connectorConnect.showUI()
  }, [])
  useEffect(() => {
    const connectorName = JSON.parse(
      localStorage.getItem("wagmi.wallet") ?? ""
    );
    setWalletName(connectorName ?? "");
  }, [account]);
  const renderWallet = useMemo(() => {
    switch (walletName) {
      case "magic":
        return (
          <StyledMagicLink onClick={handleMagicShowNFT} className="cursor-pointer flex items-center gap-2">
            <MagicLink />
            <p className="mt-1 text-18px"> Magic Link</p>
          </StyledMagicLink>
        );
      case "metaMask":
        return (
          <div className="flex items-center gap-2">
            <MetamaskIcon />
            <p className="mt-1 text-18px"> MetaMask</p>
          </div>
        );
      default:
        return (
          <div className="flex items-center gap-2">
            <WalletConnectIcon />
            <p className="mt-1 text-18px"> WalletConnect</p>
          </div>
        );
    }
  }, [walletName]);
  return <div>{renderWallet}</div>;
};
