import React, { FC } from 'react';


// import { joinClassNames } from 'utils/styling';
import useCopyClipboard from '../../hooks/useCopyClipboard';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';

interface CopyHelperProps {
  colorIcon?: string;
  fontSizeIcon?: number;
  toCopy: string;
  children?: React.ReactNode;
}

export const CopyHelper: FC<CopyHelperProps> = ({ colorIcon, fontSizeIcon, toCopy, children }) => {
  const [isCopied, setCopied] = useCopyClipboard();

  return (
    <div className="inline-flex items-center text-blue" onClick={() => setCopied(toCopy)}>
      {isCopied && (
        <div className="flex items-center space-x-1 relative">
          {children}
          <CheckCircleOutlined
            style={{
              fontSize: `${fontSizeIcon ? fontSizeIcon : '16'}px`,
              color: `${colorIcon ? colorIcon : '#ffffff'}`,
            }}
            className="cursor-pointer"
          />
        </div>
      )}

      {!isCopied && (
        <>
          {children}
          <CopyOutlined
            style={{
              fontSize: `${fontSizeIcon ? fontSizeIcon : '16'}px`,
              color: `${colorIcon ? colorIcon : '#ffffff'}`,
            }}
            className="cursor-pointer pl-1"
          />
        </>
      )}
    </div>
  );
};
