import React, { useCallback, useState } from "react";
import Drawer from "antd/lib/drawer";
import Image from "next/image";
import { Box, useActiveWeb3React } from "app-sdk";
import styled from "styled-components";
import ApevineIcon from "components/Icon/ApevineIcon";
import { useWallet } from "hooks/useWallet";
import SecondaryButton from "components/common/secondaryButton";
import { LINKS } from "./Header";
import classNames from "classnames";
import { useRouter } from "next/router";
import { AccountInfo } from "components/ModalAccountInfo/AccountInfo";
import useAuth from "hooks/useAuth";
import {useUserSelector} from '../store/user';
import {NEXT_PUBLIC_ENV} from '../config';

const WrappedDrawer = styled.div`
  .ant-drawer-content {
    background: red !important;
  }
  .ant-drawer-header {
    border: none;
  }
`;
const MenuMobile = () => {
  const user = useUserSelector()
  const [open, setOpen] = useState(false);
  const showDrawer = useCallback(() => {
    setOpen(true);
  }, []);
  const { logout } = useAuth();

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);
  const { connect } = useWallet();
  const route = useRouter();
  const asPath = route.asPath;
  const handleChangePath = useCallback(
    (e: any) => {
      const path = e.currentTarget.id;
      route.push(path);
      setOpen(false);
    },
    [route]
  );
  const { account } = useActiveWeb3React();
  const handleLogout = useCallback(() => {
    logout();
    onClose();
  }, [logout, onClose]);
  return (
    <div className="flex lg:hidden">
      <div className="flex gap-2 items-center">
        <AccountInfo />
        <Box onClick={showDrawer}>
          <Image
            src="/icons/menu.svg"
            width={48}
            height={48}
            alt="mobile-menu"
          />
        </Box>
      </div>
      <WrappedDrawer>
        <Drawer
          title={
            <div>
              <div className="flex justify-between items-center relative">
                <ApevineIcon logoBlack={false} />
                <div className="flex gap-2 items-center">
                  <Image
                    src="/icons/close-icon.svg"
                    width={32}
                    height={32}
                    alt="mobile-menu"
                    onClick={onClose}
                  />
                </div>
              </div>
              <Image
                src="/icons/header-line.svg"
                width={1700}
                height={8}
                alt="header-line"
              />
            </div>
          }
          placement={"right"}
          closable={false}
          onClose={onClose}
          open={open}
        >
          <div
            className={classNames("flex  w-full mb-6 justify-center")}
          >
            {(!account && !user.tokens) && (
              <SecondaryButton
                text="Login"
                onClick={connect}
                buttonStyles="px-1"
                className="w-[150px]"
                disabled={NEXT_PUBLIC_ENV === 'PRODUCTION'}
              />
            )}
          </div>
          <>
            {LINKS.map((item, index) => {
              const isActive = asPath === item.href;
              return (
                <Box key={item.href} onClick={handleChangePath} id={item.href}>
                  <Image
                    src="/icons/union.svg"
                    alt="nft-detail-divider"
                    width={416}
                    height={12}
                  />
                  <p
                    className={classNames(
                      " text-24px py-3 hover-scale relative text-center",
                      {
                        "text-white": !isActive,
                        "text-gray-400": !isActive,
                        "text-gradient": isActive,
                      }
                    )}
                  >
                    <p>{item.title}</p>
                  </p>
                </Box>
              );
            })}
          </>
          <Image
            src="/icons/union.svg"
            alt="nft-detail-divider"
            width={416}
            height={12}
          />
          <a
            href="https://www.earthwise.global/game-support"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <p className="text-24px py-3 text-gray-400 text-center cursor-pointer">Support</p>
          </a>
          {account && (
            <Box
              className="text-center text-error text-18px mt-5"
              onClick={handleLogout}
            >
              Log out
            </Box>
          )}
        </Drawer>
      </WrappedDrawer>
    </div>
  );
};
export default MenuMobile;
