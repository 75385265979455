import classNames from "classnames";
import { StyledButtonPrimary } from "./PrimaryGradientButton";
import { ReactNode } from "react";

interface Props<T> {
  text: string | ReactNode;
  type?: T;
  onClick?: any;
  className?: string;
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  buttonStyles?: string;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
}

const PrimaryButton = ({
  text,
  type,
  onClick = () => {},
  className,
  icon,
  disabled,
  loading,
  buttonStyles = "px-10",
  iconLeft,
  ...rest
}: Props<any>) => {
  return (
    <StyledButtonPrimary
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      htmlType={type ?? "default"}
      className={classNames(
        `font-medium flex items-center justify-center gap-2 rounded-4xl px-5 py-3 h-auto focus:bg-red ${buttonStyles} ${className} h-[48px]`,
        {
          "hover-scale": !disabled,
        }
      )}
      {...rest}
    >
      {iconLeft}
      <p
        className={classNames(" text-18px", {
          "text-gradient": !disabled,
          "text-disabled": disabled,
        })}
      >
        {text}
      </p>
      {icon}
    </StyledButtonPrimary>
  );
};

export default PrimaryButton;
