import dayjs from "dayjs";
export const checkExpiredTime = (time: string) => {
  const targetTime = dayjs(time); // Replace this with your target time

  // Get the current time
  const currentTime = dayjs();

  // Check if the target time has passed
  const hasExpired = currentTime.isAfter(targetTime);
  return hasExpired;
};
