import classNames from "classnames";
import { StyledButtonSecondary } from "./GradientButton";

interface Props<T> {
  text: string;
  type?: T;
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  iconLeft?: React.ReactNode;
  buttonStyles?: string;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
}

const SecondaryButton = ({
  text,
  type,
  onClick = () => {},
  className,
  icon,
  disabled,
  loading,
  iconLeft,
  buttonStyles = "text-black border-transparent",
  ...rest
}: Props<any>) => {
  return (
    <div className="">
      <StyledButtonSecondary
        loading={loading}
        disabled={disabled}
        onClick={onClick}
        htmlType={type ?? "default"}
        className={classNames(
          `font-medium flex items-center justify-center gap-2 rounded-4xl  sm:text-[18px] text-xs px-5 py-3 h-auto ${buttonStyles} ${className} h-[48px]`,
          {
            "hover-scale": !disabled,
          }
        )}
        {...rest}
      >
        {iconLeft}
        <p className="text-18px">{text}</p>
        {icon}
      </StyledButtonSecondary>
    </div>
  );
};

export default SecondaryButton;
