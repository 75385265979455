import { useEffect } from "react";

export default function useSectionStorage() {
  useEffect(() => {
    if(typeof window !== "undefined"){
      return window.sessionStorage.setItem(
        "code",
        (process.env.NEXT_PUBLIC_ACCESS_CODE as string) ?? ""
      );
    }
  }, [window]);
}
