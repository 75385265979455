import {useCallback, useEffect, useMemo} from "react";
import {useActiveWeb3React} from "./useActiveWeb3React";
import {useNetwork, useSignMessage} from "wagmi";
import {fetcher} from "utils/fetch";
import {useUserAction} from "store/user";
import {useAccessToken, useUserData} from "./useUserData";
import {checkExpiredTime} from "utils/time";
import notification from "antd/lib/notification";
import {socket} from "utils/socket";
import {getLoginType} from '../../../src/utils';
import {WalletConnectDeeplinkChoice} from '../../../src/utils/walletconnect';

export const useSignData = () => {
  const { account: address } = useActiveWeb3React();
  const userData = useUserData();
  const { signIn } = useSignIn();
  const tokens = useAccessToken();
  const isExpiredToken = useMemo(() => {
    if (!tokens?.refresh || !address || !userData) {
      return false;
    }
    return checkExpiredTime(tokens?.refresh?.expires);
  }, [address, tokens?.refresh, userData]);

  useEffect(() => {
    setTimeout(() => {
      if (
        (address &&
          address?.toLowerCase() !== userData?.address?.toLowerCase()) ||
        isExpiredToken
      ) {
        address && signIn();
      }
    }, 500);
  }, [address, userData?.address, isExpiredToken]);
};

export const useSignIn = () => {
  const { chain: activeChain } = useNetwork();
  const { account: address } = useActiveWeb3React();
  const { signMessageAsync } = useSignMessage();
  const { setUserInfo } = useUserAction();
  const userData = useUserData();

  const signIn = useCallback(async () => {
    try {
      const chainId = activeChain?.id;

      if (!address || !chainId) {
        return false;
      }

      return await signMessageAsync({
        message: `${address}`,
      })
        .then(async (signature) => {
          let params = {}
          const type = getLoginType(WalletConnectDeeplinkChoice.instance.getWalletConnectDeeplinkChoice)
          if (!!type) {
            params = {
              address,
              signature,
              message: address,
              type: getLoginType(WalletConnectDeeplinkChoice.instance.getWalletConnectDeeplinkChoice)
            }
          } else {
            params = {
              address,
              signature,
              message: address
            }
          }
          const { tokens, user } = await fetcher<any>("/auth/login", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
          });
          socket?.emit("leaveRoom", {
            room: userData?.address?.toLowerCase(),
          });
          if (user) {
            setUserInfo({ userInfo: user, tokens });
          }
          WalletConnectDeeplinkChoice.instance.setWalletConnectDeeplinkChoice('')
          return true;
        })
        .finally(() => {
          return null;
        });
    } catch (error: any) {
      WalletConnectDeeplinkChoice.instance.setWalletConnectDeeplinkChoice('')
      if (error.message === "User rejected request" || error.code === 4001) {
        notification.error({
          message: "You declined the action in your wallet	",
        });
      }
      return false;
    }
  }, [activeChain?.id, address, userData?.address,  WalletConnectDeeplinkChoice.instance.getWalletConnectDeeplinkChoice]);
  return { signIn };
};
