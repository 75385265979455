import { ethers, Signer } from 'ethers';
import { getAddress, hexValue } from 'ethers/lib/utils.js';
import { Magic } from 'magic-sdk';
import { AbstractProvider } from 'web3-core';

import { ConnectExtension } from '@magic-ext/connect';
import {
  InstanceWithExtensions,
  MagicSDKAdditionalConfiguration,
  SDKBase,
} from '@magic-sdk/provider';
import { RPCProviderModule } from '@magic-sdk/provider/dist/types/modules/rpc-provider';
import { EthNetworkConfiguration } from '@magic-sdk/types';
import {
  Address,
  Chain,
  Connector,
  normalizeChainId,
  UserRejectedRequestError,
} from '@wagmi/core';
import { ConnectorNotFoundError, ChainNotConfiguredError, ProviderRpcError, RpcError, AddChainError, SwitchChainError } from 'wagmi';
import { chains } from '../../utils/wagmi';

// Define the interface for MagicConnector options
export interface MagicConnectorOptions {
  apiKey: string;
  magicSdkConfiguration?: MagicSDKAdditionalConfiguration;
  networks?: EthNetworkConfiguration[];
}

// MagicConnectConnector class extends the base wagmi Connector class
export class MagicConnectConnector extends Connector<any, MagicConnectorOptions> {
  readonly id = 'magic';
  readonly name = 'Magic';
  readonly ready = true;
  provider: (RPCProviderModule & AbstractProvider) | undefined;
  magic: InstanceWithExtensions<SDKBase, ConnectExtension[]> | undefined;

  // Constructor initializes the Magic instance
  // allows connectUI modal to display faster when connect method is called
  constructor(config: { chains?: Chain[]; options: MagicConnectorOptions }) {
    super(config);
    this.initializeMagicInstance();
  }

  // Private method to initialize the Magic instance
  private async initializeMagicInstance() {
    const { apiKey, magicSdkConfiguration, networks } = this.options;
    if (typeof window !== 'undefined') {
      this.magic = new Magic(apiKey, {
        ...magicSdkConfiguration,
        network: magicSdkConfiguration?.network || networks?.[0],
        extensions: [new ConnectExtension()],
      });

      this.provider = await this.magic?.wallet.getProvider();
    }
  }

  async showUI() {
    await this.magic?.wallet.showUI()
  }

  // Connect method attempts to connects to wallet using Magic Connect modal
  async connect() {
    try {
      const connected = Boolean(localStorage.getItem("wagmi.connected"));
      if (connected !== true) {
        await this.magic?.wallet.connectWithUI();
      }
      const provider = await this.getProvider();
      const chainId = await this.getChainId();

      if (provider) this.registerProviderEventListeners(provider);

      const account = await this.getAccount();
      return {
        account: account,
        chain: {
          id: chainId,
          unsupported: false,
        },
        provider,
      };
    } catch (error) {
      throw new UserRejectedRequestError(error);
    }
  }

  // Private method to register event listeners for the provider
  private registerProviderEventListeners(provider: RPCProviderModule & AbstractProvider) {
    if (provider.on) {
      provider.on('accountsChanged', this.onAccountsChanged);
      provider.on('chainChanged', this.onChainChanged);
      provider.on('disconnect', this.onDisconnect);
    }
  }

  // Disconnect method attempts to disconnect wallet from Magic
  async disconnect(): Promise<void> {
    try {
      await this.magic?.wallet.disconnect();
      this.emit('disconnect');
    } catch (error) {
      console.error('Error disconnecting from Magic SDK:', error);
    }
  }

  // Get connected wallet address
  async getAccount(): Promise<Address> {
    const signer = await this.getSigner();
    const account = await signer.getAddress();
    return getAddress(account);
  }

  // Get chain ID
  async getChainId(): Promise<number> {
    if (this.provider) {
      const chainId = await this.provider.request({
        method: 'eth_chainId',
        params: [],
      });
      return normalizeChainId(chainId);
    }
    const networkOptions = this.options.magicSdkConfiguration?.network;
    if (typeof networkOptions === 'object') {
      const chainID = networkOptions.chainId;
      if (chainID) return normalizeChainId(chainID);
    }
    throw new Error('Chain ID is not defined');
  }

  // Get the Magic Instance provider
  async getProvider() {
    this.provider = await this.magic?.wallet.getProvider();
    return this.provider;
  }

  // Get the Magic Instance signer
  async getSigner(): Promise<Signer> {
    const provider = new ethers.providers.Web3Provider((await this.getProvider()) as any);
    return provider.getSigner();
  }

  // Autoconnect if account is available
  async isAuthorized() {
    try {
      const walletInfo = await this.magic?.wallet.getInfo();
      return !!walletInfo;
    } catch {
      return false;
    }
  }

  // Event handler for accountsChanged event
  onAccountsChanged = (accounts: string[]): void => {
    if (accounts.length === 0) this.emit('disconnect');
    else this.emit('change', { account: getAddress(accounts[0]) });
  };

  // Event handler for chainChanged event
  onChainChanged = (chainId: string | number): void => {
    const id = normalizeChainId(chainId);
    const unsupported = this.isChainUnsupported(id);
    this.emit('change', { chain: { id, unsupported } });
  };

  // Event handler for disconnect event
  onDisconnect = (): void => {
    this.emit('disconnect');
  };

  async switchChain(chainId: number): Promise<Chain> {
    if (!this.options.networks) throw new Error('switch chain not supported: please provide networks in options');
    const normalizedChainId = normalizeChainId(chainId);
    const chain = chains.find((x) => x.id === normalizedChainId);
    if (!chain) throw new Error(`Unsupported chainId: ${chainId}`);
    const network = this.options.networks.find((x) =>
      typeof x === 'object'
        ? normalizeChainId(x.chainId as number) === normalizedChainId
        : normalizeChainId(x) === normalizedChainId
    );
    if (!network) throw new Error(`Unsupported chainId: ${chainId}`);

    if ((this.provider as any).isMetaMask) {
      await this.switchChainMetaMask(chainId)
    }
    const account = await this.getAccount();

    if (this.provider?.off) {
      this.provider.off('accountsChanged', this.onAccountsChanged);
      this.provider.off('chainChanged', this.onChainChanged);
      this.provider.off('disconnect', this.onDisconnect);
    }

    this.magic = new Magic(this.options.apiKey, {
      ...this.options.magicSdkConfiguration,
      network: network,
      extensions: [new ConnectExtension()],
    });

    this.provider = await this.magic?.wallet.getProvider();
    this.registerProviderEventListeners(this.provider as any);

    this.onChainChanged(chain.id);

    this.onAccountsChanged([account]);

    return chain;
  }


  private async switchChainMetaMask(chainId: number) {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    const id = hexValue(chainId)

    try {
      await Promise.all([
        provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: id }],
        }),
        new Promise<void>((res) =>
          this.on('change', ({ chain }) => {
            if (chain?.id === chainId) res()
          }),
        ),
      ])
      return (
        this.chains.find((x) => x.id === chainId) ?? {
          id: chainId,
          name: `Chain ${id}`,
          network: `${id}`,
          nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
          rpcUrls: { default: { http: [''] }, public: { http: [''] } },
        }
      )
    } catch (error) {
      const chain = this.chains.find((x) => x.id === chainId)
      if (!chain)
        throw new ChainNotConfiguredError({ chainId, connectorId: this.id })

      // Indicates chain is not added to provider
      if (
        (error as ProviderRpcError).code === 4902 ||
        // Unwrapping for MetaMask Mobile
        // https://github.com/MetaMask/metamask-mobile/issues/2944#issuecomment-976988719
        (error as RpcError<{ originalError?: { code: number } }>)?.data
          ?.originalError?.code === 4902
      ) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: id,
                chainName: chain.name,
                nativeCurrency: chain.nativeCurrency,
                rpcUrls: [chain.rpcUrls.public?.http[0] ?? ''],
                blockExplorerUrls: this.getBlockExplorerUrls(chain),
              },
            ],
          })

          const currentChainId = await this.getChainId()
          if (currentChainId !== chainId)
            throw new ProviderRpcError(
              'User rejected switch after adding network.',
              { code: 4001 },
            )

          return chain
        } catch (addError) {
          if (this.isUserRejectedRequestError(addError))
            throw new UserRejectedRequestError(addError)
          throw new AddChainError()
        }
      }

      if (this.isUserRejectedRequestError(error))
        throw new UserRejectedRequestError(error)
      throw new SwitchChainError(error)
    }
  }

  protected isUserRejectedRequestError(error: unknown) {
    return (error as ProviderRpcError).code === 4001
  }

}
