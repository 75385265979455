import React, { useCallback, useEffect, useState } from "react";
import Image from "next/image";
import { Box } from "app-sdk";
export const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = useCallback(() => {
    if (typeof window !== "undefined" && window) {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  }, []);
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window?.addEventListener("scroll", handleScroll);
      return () => {
        window?.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);
  return (
    <>
      {isVisible && (
        <Box
          className="fixed right-0 flex justify-center items-center gap-2 bottom-[40px] z-99999 flex-col cursor-pointer bg-[#01010C] p-3 rounded-[50%] "
          onClick={scrollToTop}
        >
          <Image
            src="/icons/arrow-top.svg"
            width={24}
            height={24}
            alt="arrow-top"
          />
          <p className="text-gradient text-[14px]">Back to top</p>
        </Box>
      )}
    </>
  );
};
