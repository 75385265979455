import "antd/dist/antd.css";
import "styles/index.scss";
import "windi.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { usePollBlockNumber } from "app-sdk";
import MulticallUpdater from "app-sdk/store/multicall/updater";
import useEagerConnect from "hooks/useEagerConnect";
import useSectionStorage from "hooks/useSectionStorage";
import DefaultLayout from "layouts";
import CheckBrowserModal from "layouts/NetworkModal/CheckBrowserModal";
import WalletSignMessage from "layouts/NetworkModal/WalletSignMessage";
import WalletWrongNetwork from "layouts/NetworkModal/WalletWrongNetwork";
import CookieNotification from "layouts/Notification/Cookie";
import WalletModal from "layouts/Wallet/WalletModal";
import { NextPage } from "next";
import Providers from "providers";
import { useEffect, useMemo } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, useStore } from "store";

import type { AppProps } from "next/app";
import { BackToTop } from "components/common/BackToTop";
import { useSignData } from "app-sdk/hooks/useSignData";
import { NextSeo } from "next-seo";
import Head from "next/head";
declare global {
  interface Window {
    attachEvent: any;
    dispatchevent: any;
    Donorbox: any;
  }
}

function GlobalHooks() {
  useEagerConnect();
  useSectionStorage();
  // useNetworkConnectorUpdater()
  usePollBlockNumber();
  useSignData();
  return null;
}

function Updater() {
  return <MulticallUpdater />;
}

function App(props: AppProps<{ initialReduxState: any }>) {
  const GlobalComponents = useMemo(() => {
    return (
      <>
        <WalletModal />
        <WalletWrongNetwork />
        <WalletSignMessage />
        <CheckBrowserModal />
        <CookieNotification />
      </>
    );
  }, []);
  const { pageProps } = props as any;
  const store = useStore(pageProps.initialReduxState);
  const { url, banner, titleSeo, descriptionSEO } = pageProps?.data ?? {};
  const title =
    titleSeo ??
    "Elowyn: Quest of Time, the EARTHwise Game for World Transformation";
  const description =
    descriptionSEO ??
    "Join Elowyn: Quest of Time, an immersive, open-world adventure RPG that gives you the Keys to forge new destinies for a bountiful world and promising future. Fusing ancient myths and future legends in the quest for a new timeline to shift the balance of power on Earth. A new paradigm ‘Play2Thrive’ game by EARTHwise.";
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description,
          images: [
            {
              url: banner,
              alt: "Og Image Alt",
            },
          ],
          siteName: "Elowyn: Quest of Time",
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />{" "}
      <Head>
        <meta prefix="og: http://ogp.me/ns#" />
      </Head>
      <Providers store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalHooks />
          <Updater />
          {GlobalComponents}
          <AppBody {...props} />
        </PersistGate>
      </Providers>
    </>
  );
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>;
  /**
   * allow chain per page, empty array bypass chain block modal
   * */
  chains?: number[];
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  pageProps?: any;
};

function AppBody(props: AppPropsWithLayout) {
  const { pageProps, Component } = props;

  const Layout = Component.Layout || DefaultLayout;
  const sessionStorage_transfer = function (event: any) {
    if (!event) {
      event = window.event;
    } // ie suq

    if (!event.newValue) return; // do nothing if no value to work with

    if (event.key == "getSessionStorage") {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage)); // the other tab should now have it, so we're done with it.
      localStorage.removeItem("sessionStorage"); // <- could do short timeout as well.
    } else if (event.key == "sessionStorage" && !sessionStorage.length) {
      // another tab sent data <- get it
      const data = JSON.parse(event.newValue);
      for (let key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener("storage", sessionStorage_transfer, false);
    } else {
      window.attachEvent("onstorage", sessionStorage_transfer);
    }
    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem("getSessionStorage", "accessCode");
      localStorage.removeItem("getSessionStorage");
    }
  }, []);
  return (
    <>
      <Layout>
        <BackToTop />
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default App;
