export interface NFTAttribute {
  key: string;
  value: string;
}

export interface NFT {
  _id: string;
  name: string;
  description: string;
  image: string;
  attributes: NFTAttribute[];
  status: string;
  createdAt: string;
  collectionName: string;
  totalSupply: number;
  onSaleQuantity: number;
  unitPrice: number;
  usd: number;
  token?: IERC721;
  totalMinted: number;
  externalLink?: string;
  isDeleted?: boolean;
  nftId?:string
}

export interface NFTCollectionResponse {
  docs: NFT[];
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: number | null;
  nextPage: number | null;
}

export interface IERC721 {
  address: string;
  standard: string;
  totalSupply: number;
  tokenId?: number;
  totalMinted: number;
  typeMint: number;
}

export interface NftQueryParams {
  page: string;
  limit: string;
  sortType?: "asc" | "desc" | "all";
  keyword?: string;
  fromPrice?: string;
  toPrice?: string;
  status?: NFTStatus;
  excludeIds?: string;
  type?: "all" | "popular" | "trending" | "rare";
}

export const enum NFTStatus {
  ON_SALE = "on-sale",
  OFF_SALE = "off-sale",
  SOLD_OUT = "sold-out",
}
