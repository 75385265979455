import classNames from "classnames";
import React from "react";
import styled from "styled-components";

const WrapElement = styled.div<{
  sameWidth: boolean;
}>`
  .custom-wrapped-border {
    border-width: ${({ sameWidth }) => {
      return sameWidth ? "2px" : "4px";
    }};
  }
  z-index: 9999;

  &:hover: {
    .custom-wrapped-border-disabled,
    .custom-wrapped-border-disabled:before {
      border-style: solid;
      border-color: #af862c !important;
      border-image: linear-gradient(
        30deg,
        #af862c 0%,
        #f8f29c 42.54%,
        #d9bd5c 66.56%,
        #ad842a 100%
      ) !important;
      border-image-slice: 1;
    }
  }
`;
export const WrapCustomBorder = ({
  isDisabled = false,
  sameWidth = false,
  className = "",
  isError = false,
}) => {
  return (
    <WrapElement sameWidth={sameWidth}>
      <div
        className={classNames(
          `w-full h-full absolute top-0 left-0 ${className} `,
          {
            "custom-wrapped-border": !isDisabled,
            "custom-wrapped-border-disabled": isDisabled,
            "error-box-shadow ": isError,
          }
        )}
      >
        <div
          style={{
            top: "-4px",
            left: "-4px",
          }}
          className={classNames("corner-element border-[2px]", {
            "border-[#e9e08e]": !isDisabled && !sameWidth,
            "border-[#31323C]": isDisabled,
            "border-[#c5ac54]": sameWidth,
          })}
        />
        <div
          style={{
            top: "-4px",
            right: "-4px",
          }}
          className={classNames("corner-element border-[2px]", {
            "border-[#af862c]": !isDisabled,
            "border-[#31323C]": isDisabled,
          })}
        />
        <div
          className={classNames("corner-element border-[2px]", {
            "border-[#af862c]": !isDisabled,
            "border-[#31323C]": isDisabled,
          })}
          style={{
            bottom: "-4px",
            left: "-4px",
          }}
        />{" "}
        <div
          className={classNames("corner-element border-[2px]", {
            "border-[#e9e08e]": !isDisabled && !sameWidth,
            "border-[#31323C]": isDisabled,
            "border-[#c5ac54]": sameWidth,
          })}
          style={{
            bottom: "-4px",
            right: "-4px",
          }}
        />
      </div>
    </WrapElement>
  );
};
