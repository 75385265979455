import {Box, Currency, formatAddress, getUSDCBalance, useActiveWeb3React, useEthBalance,} from "app-sdk";
import {CopyHelper} from "components/CopyHelper";
import useAuth from "hooks/useAuth";
import {useWallet} from "hooks/useWallet";
import Image from "next/image";
import {useCallback, useEffect, useState} from "react";
import {formatEther} from "@ethersproject/units";
import PrimaryButton from "components/common/button";
import {fetcher} from "utils/fetch";
import {roundDown} from "utils/format";
import {WrapCustomBorder} from "components/WrapContent/WrapCustomBorder";
import {formatNumber} from "utils/string";
import Modal from "antd/lib/modal";
import {ConnectorName} from "components/ConnectorName";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const ProfileModal = (props: Props) => {
  const { onCancel, isOpen } = props;
  const { isWrongNetwork,  chainId, account } = useActiveWeb3React();
  const { logout } = useAuth();
  const { balance } = useEthBalance();
  const { connect } = useWallet();
  const [price, setPrice] = useState<number>(0);
  const [USDCBalance, setUSDCBalance] = useState(0)
  const getTokenPrice = async () => {
    const data = await fetcher("/price");
    setPrice(data.MATIC);
  };
  const logoutUser = useCallback(() => {
    logout().then();
    onCancel();
  }, [logout, onCancel]);

  useEffect(() => {
    getTokenPrice().then();
    _getUSDC().then()
  }, []);

  const _getUSDC = useCallback(async () => {
    const res = await getUSDCBalance(chainId, account)
    setUSDCBalance(res)
  }, [chainId, account])

  return (
    <Modal
      open={isOpen}
      onCancel={() => onCancel()}
      className="modal-profile fashion-modal"
      bodyStyle={{
        maxHeight: "350px",
        height: "100%",
      }}
      style={{ top: 16 }}
      width={480}
      footer={false}
    >
      <WrapCustomBorder />

      <>
        {account && (
          <div className="relative p-5 font-medium text-16px">
            <div className="flex gap-2 items-center text-gray-300 mb-4">
              <div className="flex justify-end flex-1 ">
                {isWrongNetwork ? (
                  "Wrong Network"
                ) : (
                  <div className="flex justify-between w-full">
                    <ConnectorName />
                    <div className="font-medium">{formatAddress(account)}</div>
                  </div>
                )}
              </div>
              <div className="w-8 h-8 inline-flex items-center justify-center rounded-lg">
                <CopyHelper
                  toCopy={account as string}
                  colorIcon="#ffffff"
                ></CopyHelper>
              </div>
            </div>
            <Image
              src="/icons/union.svg"
              alt="nft-detail-divider"
              width={600}
              height={12}
            />
            <p className="text-18px text-gradient mt-4">Balance</p>
            <div className="flex flex-col gap-3 my-4">
              <div className="flex flex-row justify-between">
                <div className="text-white font-medium flex items-center gap-2">
                  <Image
                    src="/icons/matic.svg"
                    alt="usdc"
                    width={20}
                    height={20}
                  />
                  <span className="mt-1">
                    <Currency value={balance} isWei /> MATIC
                  </span>{" "}
                </div>
                <div className="text-sm font-normal">
                  {formatNumber(
                    balance ? roundDown(price * +formatEther(balance), 3) : null
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-white font-medium flex items-center gap-2">
                  <Image
                    src="/icons/usdc.svg"
                    alt="usdc"
                    width={20}
                    height={20}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <span className="mt-1">{USDCBalance} USDC</span>
                </div>
                <div className=" text-sm font-normal">
                  {formatNumber(USDCBalance)}
                </div>
              </div>
            </div>
            <Image
              src="/icons/union.svg"
              alt="nft-detail-divider"
              width={600}
              height={12}
            />
            <Box
              onClick={logoutUser}
              className="text-[18px] text-error text-center mt-3 cursor-pointer"
            >
              Log out
            </Box>
          </div>
        )}
        {!account && (
          <PrimaryButton
            className="mx-auto"
            text="Connect Wallet"
            onClick={connect}
          />
        )}
      </>
    </Modal>
  );
};

export default ProfileModal;
