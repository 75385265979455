import ButtonWhite from 'components/Button/ButtonWhite';
import { useEffect, useState } from 'react';

const key = 'hide-popup-cookie';

const CookieNotification = () => {
  const [hideCookie, setHideCookie] = useState(true);
  useEffect(() => {
    const hideCookie = Boolean(localStorage.getItem(key));
    setHideCookie(hideCookie);
  }, []);

  const handleHideCookie = () => {
    setHideCookie(true);
    localStorage.setItem(key, 'true');
  };

  if (hideCookie) return null;

  return (
    <div className="fixed bottom-0 left-0 w-full bg-white z-20 px-6 py-3">
      <div className="flex justify-between items-center gap-3 flex-col sm:flex-row">
        <div className="text-[#3A3A3C]">
          {`By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience
          and to help our website run effectively. `}
          <a
            href="https://www.businessinsider.com/guides/tech/how-to-enable-cookies-in-google-chrome"
            target="_blank"
            rel="noreferrer"
            className="hover:text-[#3A3A3C]"
          >
            <u>Instructions</u>
          </a>
        </div>
        <ButtonWhite
          onClick={handleHideCookie}
          className="text-[#E5E5EA] bg-[#1A1A1C] hover:bg-[#37373b] py-2.5 justify-center w-full sm:w-auto"
          text="I understand"
        />
      </div>
    </div>
  );
};

export default CookieNotification;
