import { IPaginationResponse } from "types/api.type";
import { IBodyClaimItem, IItem } from "types/item";
import { IAsset } from "types/models/asset";
import { ListOwnerShip } from "types/models/item";
import { IInfoMarketResponse } from "types/models/marketplace";
import {
  NFT,
  NFTCollectionResponse,
  NFTStatus,
  NftQueryParams,
} from "types/models/nft";
import { ListOrder } from "types/models/order";
import {
  IStoryDetail,
  PaginatedResponse,
  QueryParams,
} from "types/models/story";
import { fetcher, fetcherDelete, fetcherPatch, fetcherPost, simpleFetcher } from "utils/fetch";

export const getItemsAPI = async (
  params: any
): Promise<IPaginationResponse<IItem>> => {
  const query = new URLSearchParams();
  // @ts-ignore
  Object.keys(params).forEach(
    (key) => params[key] && query.append(key, params[key])
  );
  return fetcher(`/items?${query.toString()}`);
};

export const getItemDetailAPI = async (slug: string): Promise<IItem> => {
  return fetcher(`/items/slug/${slug}`);
};

export const patchToggleEnableItemAPI = async (
  slug: string
): Promise<IItem> => {
  return fetcher(`/items/${slug}/toggle-enable`, { method: "PATCH" });
};

export const patchItemToWebsiteAPI = async (slug: string): Promise<IItem> => {
  return fetcher(`/items/${slug}/change-default-slug`, { method: "PATCH" });
};

export const deleteItemDraftAPI = async (id: string): Promise<IItem> => {
  return await fetcher(`/items/delete/${id}`, {
    method: "DELETE",
  });
};

export const getDataListingAllAPI = async (
  slug: string
): Promise<ListOrder> => {
  return fetcher(`/orders/listing-all?page=1&limit=10&slug=${slug}`);
};

export const getOwnershipAPI = async (
  item_id: string,
  address: string
): Promise<ListOwnerShip> => {
  return fetcher(
    `/assets/ownership?page=1&limit=1000&item_id=${item_id}&owner_address=${address}&index=1`
  );
};

export const getMarketInfoAPI = async (
  network: string,
  currency?: string
): Promise<IInfoMarketResponse> => {
  const query = new URLSearchParams();
  query.append("network", network);
  if (currency) {
    query.append("currency", currency);
  }
  return fetcher(`/items/marketplace-info?${query.toString()}`);
};

export const getOwnershipDetailAPI = async (
  slug: string,
  tokenId: string
): Promise<IAsset> => {
  return fetcher(`/assets/ownership/${slug}/${tokenId}`);
};

export const getOwnershipDetailByClaimIndexAPI = async (
  slug: string,
  index: string
): Promise<IAsset> => {
  return fetcher(`/assets/ownership/${slug}/index-claim/${index}`);
};

export const postClaimWineOfNftAPI = async (
  data: IBodyClaimItem
): Promise<any> => {
  return fetcherPost(`/shopify/order`, data);
};

export const postClaimMultipleWineOfNftAPI = async (
  data: IBodyClaimItem
): Promise<any> => {
  return fetcherPost(`/shopify/order/multiple`, data);
};

export const postMakePaypalAPI = async (data: {
  itemId: string;
  returnUrl: string;
  cancelUrl: string;
}): Promise<any> => {
  return fetcherPost(`/paypal/make-payment`, data);
};

export const checkPaypalAPI = async (token: string): Promise<any> => {
  return fetcher(`/paypal/success?payId=${token}`);
};

export const postTestEmail = (email: string) => {
  return fetcherPost("/users/send-email", { email });
};

export interface IProvinceUnitedStates {
  _id: string;
  province: string;
  cities: ICityUnitedStates[];
}

export interface ICityUnitedStates {
  city: string;
}

export const getProvinceUnitedStates = (): Promise<IProvinceUnitedStates[]> => {
  return fetcher("/shopify/address/province");
};

export const getStory = (query: QueryParams): Promise<PaginatedResponse> => {
  const params = new URLSearchParams({
    page: query.page,
    limit: query.limit,
  });
  return simpleFetcher(
    `/stories?${params?.toString()}&is_publish=true&sortType=asc`
  );
};

export const getStoryById = (id: string): Promise<IStoryDetail> => {
  return simpleFetcher(`/stories/${id}`);
};

export const getListNft = (
  query: NftQueryParams
): Promise<NFTCollectionResponse> => {
  const params = new URLSearchParams({
    page: query.page,
    limit: query.limit,
    keyword: query.keyword ?? "",
    fromPrice: query?.fromPrice ?? "",
    toPrice: query?.toPrice ?? "",
    status: NFTStatus.ON_SALE,
    excludeIds: query?.excludeIds ?? "",
  });
  const currentDate = new Date();
  const toDate = new Date(
    new Date(
      currentDate.setTime(
        currentDate.getTime() -
          (currentDate.getDay() ? currentDate.getDay() : 7) *
            24 *
            60 *
            60 *
            1000
      )
    ).setUTCHours(23, 59, 59, 999)
  )
    .toISOString()
    .split("T")[0];
  const fromDate = new Date(
    new Date(
      currentDate.setTime(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000)
    ).setUTCHours(0, 0, 0, 0)
  )
    .toISOString()
    .split("T")[0];
  const type = query?.type;
  const trendingParam = new URLSearchParams({
    fromDate: fromDate,
    toDate: toDate,
    isTrending: "true",
  });

  const sortBy =
    query.sortType !== "all"
      ? `&sort[unitPrice]=${query.sortType}`
      : "&sort[createdAt]=desc";
  switch (type) {
    case "trending":
      return simpleFetcher(
        `/nfts/transaction?${trendingParam}&${params}${sortBy}`
      );
    case "popular":
      return simpleFetcher(
        `/nfts/transaction?=${query.page}&${params}${sortBy}`
      );
    case "rare":
      return simpleFetcher(`/nfts?${params}${sortBy}&isLimited=true`);
    default:
      return simpleFetcher(`/nfts?${params}${sortBy}`);
  }
};

export const getNftDetailById = (id: string): Promise<NFT> => {
  return simpleFetcher(`/nfts/${id}`);
};

export const getNftHomePage = (): any => {
  return simpleFetcher("/nfts/homepage");
};

export const uploadImage = (file: any) => {
  return fetcher("/storage-admin", {
    method: "POST",
    body: file,
  });
};

export const createNft = (body: any) => {
  return fetcherPost("/nfts-admin", body);
};

export const getAttributesConfig = () => {
  return simpleFetcher("/configuration/nft-attributes");
};

export const createTransactions = (body: {
  address: string;
  nftId: string;
}) => {
  return fetcherPost("/transactions", body);
};

export const checkoutAction = (body: { nftId: string }) => {
  return fetcherPost("/orders/checkout", body);
};

export const updateOrderId = (id: string, body: { status: string }) => {
  return fetcherPatch(`/orders/update/${id}`, body);
};

export const updateNft = (id: string, body: any) => {
  return fetcherPatch(`/nfts-admin/${id}`, body);
};

export const deleteNft = (id: string) => {
  return fetcherDelete(`/nfts-admin/${id}`);
};

export const getFooterHomepage = () => {
  return simpleFetcher("/homepage/footer");
};

