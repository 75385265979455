import { targetChainId } from '../config';
import { Address } from '../constants';

export function formatAddress(address?: string, first = 8, last = 6) {
  return address ? `${address.substring(0, first)}...${address.substring(address.length - last)}` : null;
}

export const getAddress = (address: Address, chainId = -1): string => {
  return address[chainId] ? address[chainId] : address[targetChainId];
};
